import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { truncate, isEmpty } from "lodash";
import { CardTheme } from "../style/theme";
import { mq, formatDate } from "../utils/helper";

const { FeedTitle, Creatorlink, Cardbox, CardImage, CardDetails } = CardTheme;

function FeedCard({ node, light = false }) {
  let {
    url,
    title,
    image,
    isoDate,
    contentSnippet,
    blogTitle,
    blogId,
    onHome,
  } = node;

  if (contentSnippet) {
    contentSnippet = contentSnippet.replace(/&nbsp;/g, " ");
  }
  const alternativeTitle = "[لا عنوان لهذه التدوينة]";

  return (
    <Cardbox
      onClick={(event) => {
        if (event.target.id === "blogger-link") return;
        window.open(url, "_blank");
      }}
    >
      {light || (
        <CardImage>
          <img
            src={
              onHome ? `/assets/images/${image}` : `/assets/images/7-404.jpg`
            }
            alt={isEmpty(title) ? alternativeTitle : title}
            css={{
              objectFit: "cover",
              width: 300,
              height: "100%",
              [mq[1]]: {
                width: "100%",
                marginBottom: 0,
              },
            }}
          />
        </CardImage>
      )}
      <CardDetails>
        <FeedTitle>
          {isEmpty(title) ? alternativeTitle : truncate(title, { length: 84 })}
        </FeedTitle>
        <small
          css={{
            opacity: 0.85,
          }}
        >
          {formatDate(isoDate)}
        </small>
        <small>
          {" | "}{" "}
          <Creatorlink to={`/blog/${blogId}`} as={Link} id="blogger-link">
            {blogTitle}
          </Creatorlink>
        </small>
        <p>{truncate(contentSnippet, { length: 280 })}</p>
      </CardDetails>
    </Cardbox>
  );
}

export default FeedCard;

FeedCard.propTypes = {
  node: PropTypes.object,
  light: PropTypes.bool,
};
